<template>
	<div v-if="!!chatUser" :class="{ expanded }" class="opn-ai-chat-window">
	  <div :class="{ active: !conversation }" class="conversations">
		<div class="title">
		  <div>{{ $t("intro.title") }}</div>
		  <div>{{ $t("intro.description") }}</div>
		</div>
  
		<div class="inner">
		  <div v-if="1 === 3" class="conversation">
			<div class="title">Seneste beskeder</div>
  
			<div class="inner-wrapper">
			  <div class="message">
				<strong>Dig:</strong> Har du et spørgsmål? Start en ny samtale Har
				du et spørgsmål? Start en ny samtale
			  </div>
  
			  <div class="icon">
				<i class="far fa-chevron-right fa-fw" />
			  </div>
			</div>
		  </div>
  
		  <div @click="onNewConversation" class="button">
			<div class="text">
			  <div class="title">{{ $t("intro.newConversation.title") }}</div>
			  <div class="description">
				{{ $t("intro.newConversation.description") }}
			  </div>
			</div>
  
			<div class="icon">
			  <i class="far fa-paper-plane fa-fw" />
			</div>
		  </div>
  
		  <div class="items">
			<div
			  v-for="suggestion in bot.suggestions"
			  :key="`suggestion-${suggestion.id}`"
			  @click="onSuggestion(suggestion)"
			  class="item"
			>
			  <div class="text">{{ suggestion.label }}</div>
			  <div class="icon">
				<i
				  class="far fa-fw"
				  :class="suggestion.icon || 'fa-chevron-right'"
				/>
			  </div>
			</div>
		  </div>
		</div>
	  </div>
  
	  <div :class="{ active: !!conversation }" class="conversation">
		<div @click="onBack" class="title">
		  <div class="icon">
			<i class="fas fa-chevron-left fa-fw" />
		  </div>
  
		  <div class="text">{{ $t("intro.conversation") }}</div>
		</div>
  
		<conversation
		  v-if="!!conversation"
		  :conversation="conversation"
		  :chat-user="chatUser"
		  :bot="bot"
		/>
	  </div>
	</div>
  </template>
  
  <script>
  import Client from "./Client";
  import Cookies from "./Cookies";
  import { COOKIE_ID } from "./Cookies";
  import Conversation from "./Conversation";
  
  import Echo from "laravel-echo";
  import Pusher from "pusher-js";
  
  import options from "../../../options";
  
  import axios from "axios";
  
  import Vue from "vue";
  
  export default {
	props: {
	  expanded: {
		type: Boolean,
		required: false,
		default: false,
	  },
  
	  chatUser: {
		type: Object,
		required: false,
		default: null,
	  },
	},
  
	data: () => ({
	  conversation: null,
	}),
  
	computed: {
	  bot() {
		return (this.chatUser || {}).bot || null;
	  },
	},
  
	methods: {
	  onNewConversation() {
		Client.conversations.create({}).then((response) => {
		  if (response.status !== 200) {
			return;
		  }
  
		  this.conversation = response.data;
		});
	  },
  
	  onSuggestion(suggestion) {
		Client.conversations.create({}).then((response) => {
		  if (response.status !== 200) {
			return;
		  }
  
		  this.conversation = response.data;
  
		  this.$nextTick(() => {
			window.$eventHubChat.$emit("message.create", suggestion.question);
		  });
		});
	  },
  
	  onBack() {
		this.conversation = null;
	  },
	},
  
	components: { Conversation },
  };
  </script>
  
  <style lang="scss">
  .opn-ai-chat-window {
	position: relative;
  
	width: 375px;
	height: 550px;
	max-width: calc(100vw - 25px);
	max-height: calc(100vh - 100px);
  
	border-radius: 12px;
	overflow: hidden;
	line-height: 100%;
	box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
	background-color: var(--light);
  
	overflow-y: scroll;
  
	*:not(i) {
	  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI",
		Roboto, "Helvetica Neue", Arial, sans-serif !important;
	}
  
	opacity: 0;
	transition: opacity 0.25s ease-in-out;
	pointer-events: none;
  
	&.expanded {
	  pointer-events: all;
	  opacity: 1;
	}
  
	> * {
	  position: absolute;
	  top: 0;
	  right: 0;
	  bottom: 0;
	  left: 0;
  
	  width: 100%;
	  height: 100%;
  
	  transition: all 0.25s linear;
  
	  > div.header {
		color: #fff;
		width: 100%;
		font-size: 15px;
		font-weight: 500;
		padding: 10px;
		text-align: center;
		background-image: linear-gradient(
		  to bottom right,
		  var(--primary),
		  hsl(var(--primary-h), var(--primary-s), calc(var(--primary-l) - 20%))
		);
	  }
	}
  
	> div.conversation {
	  display: flex;
	  flex-direction: column;
  
	  transform: translateX(100%);
  
	  &.active {
		transform: translateX(0);
	  }
  
	  > div.title {
		position: relative;
		z-index: 0;
  
		display: flex;
		align-items: center;
		gap: 10px;
		padding: 20px;
		background-image: linear-gradient(
		  90deg,
		  var(--primary) 1.5%,
		  hsl(var(--primary-h), var(--primary-s), calc(var(--primary-l) - 20%))
			91.6%
		);
  
		color: var(--light);
  
		> div.icon {
		  font-size: 12px;
		}
  
		> div.text {
		  font-size: 15px;
		  font-weight: 500;
		}
	  }
	}
  
	> div.conversations {
	  transform: translateX(-100%);
  
	  &.active {
		transform: translateX(0);
	  }
  
	  > * {
		position: relative;
		z-index: 1;
	  }
  
	  > div.title {
		position: relative;
		z-index: 0;
  
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		padding: 100px 25px 180px 25px;
		margin: 0 0 -180px 0;
		background-image: linear-gradient(
		  to bottom right,
		  var(--primary),
		  hsl(var(--primary-h), var(--primary-s), calc(var(--primary-l) - 20%))
		);
  
		line-height: 25px;
  
		> div {
		  color: var(--light);
		  font-size: 20px;
		  letter-spacing: -0.4px;
		  font-weight: 600;
  
		  &:nth-child(1) {
			opacity: 0.7;
		  }
		}
  
		&:after {
		  position: absolute;
		  right: 0;
		  bottom: 0;
		  left: 0;
		  height: 120px;
		  content: "";
		  background: linear-gradient(
			180deg,
			hsla(var(--light-h), var(--light-s), var(--light-l), 0) 10%,
			hsla(var(--light-h), var(--light-s), var(--light-l), 1) 100%
		  );
		}
	  }
  
	  > div.inner {
		padding: 20px;
  
		display: flex;
		flex-direction: column;
		gap: 15px;
  
		> div.conversation {
		  display: flex;
		  flex-direction: column;
  
		  padding: 20px;
		  border-radius: 8px;
		  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
		  background-color: var(--light);
  
		  cursor: pointer;
		  user-select: none;
  
		  &:hover {
			background-color: hsl(
			  var(--light-h),
			  var(--light-s),
			  calc(var(--light-l) - 5%)
			);
		  }
  
		  > div.title {
			color: var(--dark);
			font-size: 14px;
			font-weight: 500;
			line-height: 100%;
		  }
  
		  > div.inner-wrapper {
			display: flex;
			align-items: center;
			justify-content: space-between;
			gap: 10px;
  
			> div.message {
			  color: hsl(var(--dark-h), var(--dark-s), calc(var(--dark-l) + 10%));
			  font-size: 12px;
			  font-weight: 400;
  
			  white-space: nowrap;
			  min-width: 0;
			  overflow: hidden;
			  text-overflow: ellipsis;
  
			  strong {
				font-weight: 500;
			  }
			}
  
			> div.icon {
			  color: var(--primary);
			  font-size: 12px;
			}
		  }
		}
  
		> div.button {
		  display: flex;
		  align-items: center;
		  justify-content: space-between;
  
		  padding: 20px;
		  border-radius: 8px;
		  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
		  background-color: var(--light);
  
		  cursor: pointer;
		  user-select: none;
  
		  &:hover {
			background-color: hsl(
			  var(--light-h),
			  var(--light-s),
			  calc(var(--light-l) - 5%)
			);
		  }
  
		  > div.text {
			> div.title {
			  color: var(--dark);
			  font-size: 14px;
			  font-weight: 500;
			}
  
			> div.description {
			  color: hsl(var(--dark-h), var(--dark-s), calc(var(--dark-l) + 15%));
			  font-size: 13px;
			  font-weight: 400;
			}
		  }
  
		  > div.icon {
			color: var(--primary);
			font-size: 14px;
		  }
		}
  
		> div.items {
		  display: flex;
		  flex-direction: column;
		  gap: 4px;
		  padding: 10px;
		  border-radius: 8px;
		  background-color: var(--light);
		  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  
		  > div.item {
			display: flex;
			justify-content: space-between;
  
			padding: 10px;
			border-radius: 12px;
  
			cursor: pointer;
			user-select: none;
  
			&:hover {
			  background-color: hsl(
				var(--light-h),
				var(--light-s),
				calc(var(--light-l) - 5%)
			  );
			}
  
			> div.text {
			  color: var(--dark);
			  font-size: 14px;
			  font-weight: 400;
			}
  
			> div.icon {
			  color: var(--primary);
			  font-size: 14px;
			}
		  }
		}
	  }
	}
  }
  </style>