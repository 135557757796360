import axios from 'axios'

import options from '../../../options'

import Cookies from './Cookies'
import { COOKIE_ID } from './Cookies'

const $axios = axios.create({
	baseURL: options.API_URL,
	withCredentials: true
})

const createRepository = (resource, methods = {}) => ({
	find: (id, options = {}) => $axios({
		method: 'get',
		url: `${resource}/${id}`,
		...prepareOptions(options)
	}),

	findAll: (options = {}) => $axios({
		method: 'get',
		url: resource,
		...prepareOptions(options)
	}),

	search: (options = {}) => $axios({
		method: 'get',
		url: `${resource}/search`,
		...prepareOptions(options)
	}),

	create: (options = {}) => $axios({
		method: 'post',
		url: resource,
		...prepareOptions(options)
	}),

	update: (id, options = {}) => $axios({
		method: 'put',
		url: `${resource}/${id}`,
		...prepareOptions(options)
	}),

	delete: (id, options = {}) => $axios({
		method: 'delete',
		url: `${resource}/${id}`,
		...prepareOptions(options)
	}),
	...methods
})

const prepareOptions = options => {
	if (options === undefined) {
		options = {}
	}

	if (! options.params) {
		options.params = {}
	}

	// Body

	if (options.body && typeof options.body !== 'string') {
		options.data = options.body
	}

	// Query

	if ((options.method || '').toLowerCase() === 'get' && ! options.query) {
		options.query = {}
	}

	if (options.query) {
		options.params = options.query
		options.paramsSerializer = toQueryString
	}

	const key = Cookies.get(COOKIE_ID) || null


	if (!! key && String(key) !== 'undefined') {
		options.params.key = key
	}

	options.params = {
		...options.params || {},
		domain: window.location.host
	}

	return options
}

export default {
	conversations: createRepository('conversations'),
	messages: createRepository('conversations/messages'),
	supportTickets: createRepository('support-tickets'),

	users: createRepository('users', {
		get: (options) => $axios({
			method: 'get',
			url: `users`,
			...prepareOptions(options)
		})
	})
}

const toQueryString = (object, prefix) => {
	let string = [], key, value;

	for (var property in object) {
		if (!object.hasOwnProperty(property)) {
			continue;
		}

		key = prefix ? prefix + "[" + property + "]" : property;

		if (~property.indexOf('[')) {
			key = prefix ?
				prefix + "[" + property.substring(0, property.indexOf('[')) + "]" + property.substring(property.indexOf('[')) :
				property;
		}

		value = object[property];
		string.push(
			typeof value == "object" ?
				toQueryString(value, key) :
				encodeURIComponent(key) + "=" + encodeURIComponent(value)
		);
	}

	return string.join("&");
}
