<template>
	<div class="conversation">
	  <div class="messages">
		<div ref="messages">
		  <div class="chat-bot-notice">
			<i class="far fa-robot fa-fw"></i>
			<div class="content">
			  <div class="title">{{ $t("conversation.ai.title") }}</div>
			  <div class="description">
				{{ $t("conversation.ai.description") }}
			  </div>
			</div>
		  </div>
  
		  <template v-for="message in messages">
			<div :class="{ me: !!message.chat_user_id }" class="message">
			  <div>{{ message.message }}</div>
			</div>
  
			<div>
			  <a
				v-if="!!message.page_title && !!message.page_url"
				:href="message.page_url"
				target="_blank"
				class="message-link"
				v-text="message.page_title"
			  />
			</div>
		  </template>
  
		  <div v-if="canCreateSupportTicket" class="support-ticket">
			<div v-if="hasCreatedSupportTicket" class="success">
			  <div class="icon"><i class="fas fa-check fa-fw" /></div>
			  <div class="message">
				{{ $t("conversation.supportTicket.confirmation") }}
			  </div>
			</div>
  
			<form
			  @submit.prevent="onCreateSupportTicket"
			  v-else-if="createSupportTicket"
			>
			  <label for="email">{{
				$t("conversation.supportTicket.email")
			  }}</label>
  
			  <input type="email" v-model="email" />
  
			  <button type="submit" :disabled="String(email || '').length === 0">
				{{ $t("conversation.supportTicket.button") }}
			  </button>
			</form>
  
			<button
			  type="button"
			  @click="createSupportTicket = true"
			  class="show-support-ticket"
			  v-else
			>
			  {{ $t("conversation.supportTicket.new") }}
			</button>
		  </div>
  
		  <div v-if="locked" class="message">
			<div>
			  <div class="typing">
				<div v-for="n in 3" />
			  </div>
			</div>
		  </div>
		</div>
	  </div>
  
	  <form @submit.prevent="send" class="send">
		<input
		  type="text"
		  ref="input"
		  v-model="message"
		  :disabled="locked"
		  :placeholder="$t('conversation.message.placeholder')"
		/>
  
		<button type="submit" :disabled="locked">
		  <i class="fas fa-paper-plane fa-fw" />
		</button>
	  </form>
	</div>
  </template>
  
  <style lang="scss" scoped>
  @keyframes typing-dot {
	0% {
	  transform: translateY(0%);
	}
	15% {
	  transform: translateY(-150%);
	}
	30% {
	  transform: translateY(0);
	}
	100% {
	  transform: translateY(0);
	}
  }
  
  div.conversation {
	flex: 1;
  
	display: flex;
	flex-direction: column;
	gap: 10px;
  
	> div.messages {
	  flex: 1;
  
	  position: relative;
  
	  > div {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
  
		overflow-y: scroll;
  
		padding: 25px;
  
		display: flex;
		flex-direction: column;
		gap: 10px;
  
		> div.chat-bot-notice {
		  display: flex;
		  align-items: flex-start;
		  gap: 10px;
		  border-radius: 6px;
		  padding: 15px;
		  background-color: hsl(
			var(--light-h),
			var(--light-s),
			calc(var(--light-l) - 10%)
		  );
		  margin: 0 0 8px 0;
  
		  > i {
			font-size: 15px;
		  }
  
		  > div.content {
			flex: 1;
  
			> div.title {
			  color: var(--dark);
			  font-size: 14px;
			  font-weight: 600 !important;
			}
  
			> div.description {
			  color: hsl(var(--dark-h), var(--dark-s), calc(var(--dark-l) + 10%));
			  font-size: 12px;
			  font-weight: 400;
			  line-height: 25px;
			}
		  }
		}
  
		a.message-link {
		  margin: 3px 0 6px 0;
		  display: inline-block;
		  color: hsl(var(--dark-h), var(--dark-s), calc(var(--dark-l) + 20%));
		  border: 1px solid
			hsl(var(--light-h), var(--light-s), calc(var(--light-l) - 10%));
		  padding: 7px 8px;
		  border-radius: 32px;
		  font-size: 13px;
		  font-weight: 400;
		}
  
		> div.message {
		  display: flex;
  
		  > div {
			max-width: calc(100% - 40px);
			padding: 12px;
			font-size: 13px;
			font-weight: 400;
			border-radius: 6px;
			line-height: 25px;
			background-color: hsl(
			  var(--light-h),
			  var(--light-s),
			  calc(var(--light-l) - 10%)
			);
		  }
  
		  .typing {
			display: flex;
			align-items: center;
			gap: 4px;
			padding: 5px 0;
  
			> div {
			  width: 5px;
			  height: 5px;
			  min-width: 5px;
			  min-height: 5px;
			  border-radius: 50%;
			  background-color: var(--dark);
  
			  animation: typing-dot 2s ease-in-out infinite;
  
			  &:nth-child(2) {
				animation-delay: 0.15s;
			  }
  
			  &:nth-child(3) {
				animation-delay: 0.3s;
			  }
			}
		  }
  
		  &.me {
			justify-content: flex-end;
  
			> div {
			  color: var(--light);
			  background-color: hsl(
				var(--primary-h),
				var(--primary-s),
				calc(var(--primary-l) - 20%)
			  );
			}
		  }
		}
  
		> div.items {
		  display: flex;
		  flex-direction: column;
		  align-items: flex-end;
		  gap: 10px;
		  margin-top: auto;
		  padding-top: 25px;
  
		  > div.item {
			color: hsl(var(--dark-h), var(--dark-s), calc(var(--dark-l) + 20%));
			font-size: 13px;
			padding: 8px 14px;
			border-radius: 16px;
			box-shadow: rgba(var(--dark), 0.02) 0px 1px 3px 0px,
			  rgba(var(--dark), 0.15) 0px 0px 0px 1px;
  
			cursor: pointer;
			user-select: none;
  
			background-color: var(--light);
  
			&:hover {
			  background-color: hsl(
				var(--dark-h),
				var(--dark-s),
				calc(var(--dark-l) - 5%)
			  );
			}
		  }
		}
	  }
	}
  
	> form.send {
	  position: relative;
	  border-top: 1px solid
		hsl(var(--light-h), var(--light-s), calc(var(--light-l) - 15%));
	  display: flex;
	  justify-content: space-between;
	  align-items: center;
	  padding: 10px 20px;
	  > input {
		border: 0;
		outline: 0;
		box-shadow: none;
		width: 100%;
		font-size: 14px;
		padding: 0;
		color: var(--dark);
		font-style: normal;
		margin-bottom: 0;
		padding-right: 10px;
		background-color: transparent;
  
		&:disabled {
		  background-color: transparent;
		  cursor: not-allowed;
		}
  
		&::placeholder {
		  color: var(--dark);
		  opacity: 0.5;
		}
	  }
  
	  > button {
		color: var(--primary);
		font-size: 14px;
		width: 35px;
		height: 35px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 50%;
		border: 0;
		padding: 0;
		background-color: var(--light);
		cursor: pointer;
		flex-shrink: 0;
		background-color: hsl(
		  var(--light-h),
		  var(--light-s),
		  calc(var(--light-l) - 3%)
		);
  
		&:disabled {
		  opacity: 0.6;
		}
  
		&:hover {
		  background-color: hsl(
			var(--light-h),
			var(--light-s),
			calc(var(--light-l) - 5%)
		  );
		}
	  }
	}
  }
  
  .support-ticket {
	display: flex;
	flex-direction: column;
  
	> div.success {
	  background-color: #fff;
	  padding: 15px;
	  display: flex;
	  gap: 10px;
	  border: 1px solid #ccc;
	  border-radius: 8px;
	  font-size: 12px;
	}
  
	> form {
	  display: flex;
	  justify-content: flex-end;
	  flex-direction: column;
	  align-self: flex-end;
	  gap: 6px;
  
	  border: 1px solid #ccc;
	  padding: 15px;
	  border-radius: 8px;
  
	  > label {
		font-size: 13px;
		font-weight: 500;
		letter-spacing: -0.1px;
		color: #393939;
		margin: 0;
	  }
  
	  > input {
		padding: 5px 10px;
		border-radius: 8px;
		border: 1px solid #ccc;
		font-size: 13px;
		box-shadow: none;
		outline: 0;
	  }
  
	  > button {
		background: #000;
		color: #fff;
		border-radius: 8px;
		border: 0;
		font-size: 13px;
		font-weight: 500;
		padding: 8px;
  
		&:disabled {
		  opacity: 0.7;
		}
	  }
	}
  
	button.show-support-ticket {
	  align-self: flex-end;
	  font-size: 13px;
	  background-color: #fff;
	  padding: 10px 15px;
	  border: 1px solid #ccc;
	  border-radius: 8px;
	  cursor: pointer;
	  user-select: none;
	  color: var(--dark);
	  &:hover {
		background-color: #eee;
	  }
	}
  }
  </style>
  
  <script>
  import Client from "./Client";
  
  export default {
	props: {
	  conversation: {
		type: Object,
		required: true,
	  },
  
	  chatUser: {
		type: Object,
		required: true,
	  },
  
	  bot: {
		type: Object,
		required: true,
	  },
	},
  
	data: () => ({
	  messages: [],
	  message: "",
  
	  channel: null,
	  locked: false,
  
	  scrollTop: 0,
  
	  email: "",
	  createSupportTicket: false,
	  hasCreatedSupportTicket: false,
	}),
  
	computed: {
	  canCreateSupportTicket() {
		const messages = this.messages;
  
		if (messages.length < 2) {
		  return false;
		}
  
		const bot = this.bot || {};
  
		return String(bot.support_email || "").length > 0;
	  },
  
	  scrolledToBottom() {
		const $el = this.$refs.messages;
  
		if (!$el) {
		  return;
		}
  
		return this.scrollTop + 20 > $el.scrollHeight - $el.offsetHeight;
	  },
	},
  
	watch: {
	  locked() {
		if (this.locked) {
		  return;
		}
  
		window.setTimeout(() => this.$refs.input.focus(), 0);
	  },
	},
  
	mounted() {
	  this.channel = Echo.private(`conversations.${this.conversation.id}`).listen(
		"Api\\Conversation\\Events\\Message\\BroadcastMessageEvent",
		(payload) => {
		  this.pushMessage(payload.message);
		  this.locked = false;
		}
	  );
  
	  this.$nextTick(() => {
		const $el = this.$refs.messages;
  
		this.setScroll();
		$el.addEventListener("scroll", this.setScroll);
	  });
  
	  window.$eventHubChat.$on("message.create", this.onSendMessage);
	},
  
	beforeDestroy() {
	  Echo.leaveChannel(`conversations.${this.conversation.id}`);
  
	  this.$refs.messages.removeEventListener("scroll", this.setScroll);
  
	  window.$eventHubChat.$off("message.create", this.onSendMessage);
	},
  
	methods: {
	  setScroll() {
		const $el = this.$refs.messages;
  
		if (!$el) {
		  return;
		}
  
		this.scrollTop = $el.scrollTop;
	  },
  
	  send() {
		if (this.locked) {
		  return;
		}
  
		const message = this.message;
		this.message = "";
  
		this.onSendMessage(message);
	  },
  
	  onSendMessage(message) {
		this.locked = true;
  
		this.pushMessage(
		  {
			id: new Date().getTime(),
			message,
			chat_user: this.chatUser,
			chat_user_id: this.chatUser.id,
		  },
		  true
		);
  
		Client.messages.create({
		  body: {
			message,
			conversation_id: this.conversation.id,
		  },
		});
	  },
  
	  pushMessage(message, me = false) {
		const scrolledToBottom = this.scrolledToBottom || me;
  
		this.messages.push(message);
  
		if (scrolledToBottom) {
		  this.$nextTick(() => {
			const $el = this.$refs.messages;
			$el.scrollTop = $el.scrollHeight;
		  });
		}
	  },
  
	  onCreateSupportTicket() {
		if (!this.canCreateSupportTicket) {
		  return;
		}
  
		Client.supportTickets.create({
		  body: {
			email: this.email,
			conversation_id: this.conversation.id,
		  },
		});
  
		this.hasCreatedSupportTicket = true;
	  },
	},
  };
  </script>