import Cookies from 'js-cookie'

export const COOKIE_ID = 'ai_chat_user'

export default {
	set(key, value) {
		Cookies.set(key, value)
	},

	get(key) {
		return Cookies.get(key)
	},

	remove(key) {
		Cookies.remove(key)
	}
}
